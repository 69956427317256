import { useCallback, useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import ActivityTabs from 'components/ActivityTabs';
import AddFromActivityButton from 'components/AddFromActivityButton';
import CloseActivityButton from 'components/CloseActivityButton';
import CloseAndCompleteActivityButton from 'components/CloseAndCompleteActivityButton';
import MarkCompleteButton from 'components/MarkCompleteButton';
import MarkReopenButton from 'components/MarkReopenButton';
import MarkReviewedButton from 'components/MarkReviewedButton';
import PageBody from 'components/PageBody';
import WithPageTitle from 'components/WithPageTitle';
import MarkDirectCloseButton from 'components/MarkDirectCloseButton';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useTranslation from './hooks/useTranslation';

import useActivity from './hooks/useActivity';
import useAttachments from './hooks/useAttachments';
import useData from './hooks/useData';
import useDataActivities from './../Activities/hooks/useData';
import useStatus from './hooks/useStatus';
import { usePermissions } from 'hooks/usePermissions';

import normalizeData from './data';
import normalizeGroupData from './groupData';

import styles from './styles.module.scss';
import DangerNotice from 'components/DangerNotice';
import DownloadIcon from 'components/DownloadIcon';
import SubmitButton from 'components/SubmitButton';
import { ExportModal } from 'components/ExportModal';
import { useExportActivities } from 'hooks/useExportActivities';
import { getSchemaDataName } from 'utilities/schema';
import { getFileName } from 'utilities/files';
import { useFormCache } from 'hooks/use-form-cache.hook';
import { useOnLeaveUrl } from 'hooks/use-on-leave-url.hook';
import { useFormsRef } from 'hooks/use-forms-ref';

const OPTIONS_TO_CLOSE_ACTIVITY = ['activity:optionsClose'];
const PLUS_BUTTON_ACTIVITY = ['activity:create'];

const Activity = () => {
    const { DELETED } = useTranslation();
    const params = useParams();
    const { activityId, activitySchema } = params;

    const { useActivities, useQuerySchemas, useSchema, useTenants, useUserId } =
        useGlobalStateHooks();

    const [_activities, setActivities] = useActivities();
    const { exportDetailedActivities } = useExportActivities();
    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();

    const [activity, setActivity] = useActivity();
    const {
        loading,
        data,
        refetch: onRefetchActivity
    } = useData(activityId, activitySchema);
    const { data: activitesData, refetch: onRefetchActivityData } =
        useDataActivities();
    const { hasPermissions } = usePermissions();

    const hasPermissionInOptionsToClose = hasPermissions(
        OPTIONS_TO_CLOSE_ACTIVITY
    );
    const hasPermissionToShowPlusButton = hasPermissions(PLUS_BUTTON_ACTIVITY);
    const formCache = useFormCache();
    useOnLeaveUrl(formCache.clearStorage);

    function refetchActivityData() {
        onRefetchActivity();
        onRefetchActivityData();
    }

    const [
        createdAttachment,
        updateAttachments,
        reset,
        deleteAttachment,
        updateAttachment
    ] = useAttachments(activitySchema);
    const [activityStatus, setActivityStatus] = useStatus(activitySchema);

    const groups = useRef();
    const referencedUsers = useRef();
    const referencedHierarchy = useRef();

    const formsRef = useFormsRef();

    const {
        activity: activityRecord,
        activityEntityData,
        activityRegulatoryReferenceData: regulatoryReferences,
        allAnswers,
        allForms,
        allEntityData,
        allPreviouslyAssignedUsers,
        answers,
        comments,
        closed,
        deleted,
        entities,
        forms,
        hierarchical,
        history,
        questions,
        otherAnswers,
        otherForms,
        riskLabel,
        sendEmail,
        users = {}
    } = activity;

    const [displayedActivityUsers, setActivityUser] = useState(users);
    const [displayedAttachments, setDisplayedAttachments] = useState([]);
    const [displayedHierarchy, setDisplayedHierarchy] = useState();
    const [showExportModal, setShowExportModal] = useState(false);
    const [isCreatingPDF, setIsCreatingPDF] = useState(false);

    const {
        activityLinkages,
        activityRecurrence,
        activityUsersForRecurrence,
        caseLinkages,
        dropdowns,
        dueDate,
        id,
        instructions,
        logLinkages,
        name: activityName,
        policyReference,
        recurrenceType,
        recurrences,
        startDate,
        type,
        userGroup,
        userStatus
    } = activityRecord ? activityRecord : [];

    const onShowExportModal = () => {
        setShowExportModal(true);
    };

    const onHideExportModal = () => {
        setShowExportModal(false);
    };

    async function onBeforeCloseActivity() {
        await formsRef.submitAllForms();
    }

    const exportPDF = useCallback(async () => {
        const input = document.getElementById('activity-view');
        input.classList.add('pdf-export');

        const scale = 1.5; // Adjust the scale to balance between quality and performance
        const canvas = await html2canvas(input, {
            scale,
            scrollX: 0,
            scrollY: 0
        });

        const imgData = canvas.toDataURL('image/jpeg', 0.5); // Adjust the image quality

        const pdf = new jsPDF({
            format: 'a4',
            orientation: 'portrait',
            unit: 'mm'
        });

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = pdfWidth;
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

        const pageHeightInCanvasPixels =
            pdfHeight * (imgProps.width / pdfWidth);
        const pageCount = Math.ceil(imgHeight / pdfHeight);

        const nameFile = getFileName('Activity');

        const singlePageCanvas = document.createElement('canvas');
        const ctx = singlePageCanvas.getContext('2d');

        for (let i = 0; i < pageCount; i++) {
            if (i > 0) {
                pdf.addPage();
            }

            const sourceY = i * pageHeightInCanvasPixels;
            const targetHeight = Math.min(
                pageHeightInCanvasPixels,
                imgProps.height - sourceY
            );

            singlePageCanvas.width = imgProps.width;
            singlePageCanvas.height = targetHeight;
            ctx.clearRect(
                0,
                0,
                singlePageCanvas.width,
                singlePageCanvas.height
            );
            ctx.drawImage(
                canvas,
                0,
                sourceY,
                imgProps.width,
                targetHeight,
                0,
                0,
                imgProps.width,
                targetHeight
            );

            const pageImgData = singlePageCanvas.toDataURL('image/jpeg', 0.8);
            pdf.addImage(
                pageImgData,
                'JPEG',
                0,
                0,
                pdfWidth,
                (targetHeight / imgProps.width) * pdfWidth
            );
        }

        pdf.save(nameFile + '.pdf');
        setIsCreatingPDF(false);
    }, []);

    const handleClick = async () => {
        await onBeforeCloseActivity();

        let userToClose;
        let closedAssignmentType;

        const dataToSubmit = {
            activity: activityName,
            dueDate: dueDate,
            hierarchy: referencedHierarchy.current,
            id: activityId,
            status: 'complete'
        };
        loop1: for (let assignmentType in referencedUsers.current) {
            const assignmentNumber = assignmentType.includes('assignee')
                ? Number(assignmentType.split('-')[1])
                : 0;
            const nextAssignmentType =
                `assignee-${assignmentNumber + 1}` in referencedUsers.current
                    ? `assignee-${assignmentNumber + 1}`
                    : `assignee-${assignmentNumber + 2}` in
                      referencedUsers.current
                    ? `assignee-${assignmentNumber + 2}`
                    : `assignee-${assignmentNumber + 3}` in
                      referencedUsers.current
                    ? `assignee-${assignmentNumber + 3}`
                    : `reviewer` in displayedActivityUsers
                    ? `reviewer`
                    : null;
            dataToSubmit.nextAssignmentType = nextAssignmentType;
            dataToSubmit.nextUsers = nextAssignmentType
                ? referencedUsers.current[nextAssignmentType].users
                : [];
            const { users: assignmentUsers, status } =
                referencedUsers.current[assignmentType];
            if (status === 'inprogress') {
                for (let user of assignmentUsers) {
                    if (
                        user.lastName === 'Group' &&
                        groups.current.includes(user.id)
                    ) {
                        userToClose = user;
                        closedAssignmentType = assignmentType;
                        break loop1;
                    } else if (user.id === Number(userId)) {
                        userToClose = user;
                        closedAssignmentType = assignmentType;
                        break loop1;
                    }
                }
            }
        }
        if (userToClose.lastName === 'Group') {
            dataToSubmit.groupId = userToClose.id;
        } else {
            dataToSubmit.userId = userToClose.id;
        }
        dataToSubmit.assignmentType = closedAssignmentType;
        dataToSubmit.userName = `${userToClose.firstName} ${userToClose.lastName}`;
        setActivityStatus(dataToSubmit);
    };

    const activityMap = useRef({
        close: ({ activityId, activityRecord, isDisabled, riskLabel }) => (
            <CloseActivityButton
                activityId={activityId}
                activityRecord={activityRecord}
                activitySchema={activitySchema}
                isDisabled={isDisabled}
                onBeforeClose={onBeforeCloseActivity}
                riskLabel={riskLabel}
            />
        ),

        closeAndComplete: ({
            activityId,
            activityRecord,
            isDisabled,
            riskLabel
        }) => (
            <CloseAndCompleteActivityButton
                activityId={activityId}
                activityRecord={activityRecord}
                activitySchema={activitySchema}
                groups={groups}
                isDisabled={isDisabled}
                onBeforeClose={onBeforeCloseActivity}
                referencedUsers={referencedUsers}
                riskLabel={riskLabel}
                userId={userId}
            />
        ),

        complete: () => {
            return <MarkCompleteButton onClick={handleClick} />;
        },

        directClose: ({ activityId, isDisabled }) => (
            <MarkDirectCloseButton
                activityId={activityId}
                groups={groups}
                isDisabled={isDisabled}
                onBeforeClose={onBeforeCloseActivity}
                referencedUsers={referencedUsers}
            />
        ),

        none: () => {
            return <></>;
        },

        reopen: ({ activityId }) => {
            return (
                <MarkReopenButton
                    activityId={activityId}
                    activitySchema={activitySchema}
                    userId={userId}
                />
            );
        },

        review: () => {
            return (
                <MarkReviewedButton
                    activitySchema={activitySchema}
                    onClick={handleClick}
                />
            );
        }
    });

    const handleFileUpload = async newFiles => {
        if (Object.keys(newFiles).length > 0) {
            const variables = {};

            variables['id'] = id;
            variables['files'] = newFiles;
            await updateAttachments(variables);
            await formsRef.submitAllForms();
        }
    };

    const attachmentsOnDelete = async params => {
        const { attachmentId, filename } = params;
        await deleteAttachment({ activityId: id, attachmentId, filename });
        setDisplayedAttachments(
            displayedAttachments.filter(
                attachment => attachment.id !== attachmentId
            )
        );
    };

    const attachmentsOnUpdate = async params => {
        const { attachmentId, filename, newName } = params;
        await updateAttachment({
            activityId: id,
            attachmentId,
            filename,
            newName
        });
        setDisplayedAttachments(
            displayedAttachments.map(attachment =>
                attachment.id === attachmentId
                    ? { ...attachment, name: newName }
                    : attachment
            )
        );
    };

    useEffect(() => {
        if (data) {
            console.log('TO NORMALIZE DATA', data);
            const normalizedData = normalizeData({
                data,
                querySchemas,
                schema: activitySchema,
                user: userId
            });

            console.log('THE NORMALIZED DATA', normalizedData);

            setActivity(normalizedData);
            setDisplayedAttachments(normalizedData?.attachments);
            setDisplayedHierarchy(normalizedData?.hierarchical);

            referencedUsers.current = normalizedData?.users;
            referencedHierarchy.current = normalizedData.hierarchical;
        }
    }, [
        data,
        activitySchema,
        querySchemas,
        referencedUsers,
        setActivity,
        userId
    ]);

    useEffect(() => {
        if (data) {
            const normalizedGroupData = normalizeGroupData({
                data,
                schema: activitySchema
            });

            groups.current = normalizedGroupData;
        }
    }, [activitySchema, data, groups]);

    useEffect(() => {
        if (
            createdAttachment &&
            (createdAttachment.constructor.name !== 'Array' ||
                (Array.isArray(createdAttachment) &&
                    createdAttachment.length > 0))
        ) {
            const newDisplayedAttachments = [
                ...displayedAttachments,
                ...createdAttachment
            ];
            const uniqueAttachments = newDisplayedAttachments.filter(
                (attachment, index, self) =>
                    index === self.findIndex(t => t.id === attachment.id)
            );
            setDisplayedAttachments(uniqueAttachments);
            reset();
        }
    }, [createdAttachment, displayedAttachments, reset]);

    const addAssignee = useCallback(() => {
        const displayedActivityUsersNoReviewer = { ...displayedActivityUsers };
        if ('reviewer' in displayedActivityUsersNoReviewer) {
            delete displayedActivityUsersNoReviewer.reviewer;
        }
        const assigneeKey =
            Object.keys(displayedActivityUsersNoReviewer).length + 1;
        const newAssigneeObject = {};
        newAssigneeObject[`assignee-${assigneeKey}`] = {
            status: 'inprogress',
            users: []
        };

        setActivityUser({ ...displayedActivityUsers, ...newAssigneeObject });
    }, [displayedActivityUsers, setActivityUser]);

    const addReviewer = useCallback(() => {
        const newAssigneeObject = {};
        newAssigneeObject[`reviewer`] = { status: 'inprogress', users: [] };
        setActivityUser({ ...displayedActivityUsers, ...newAssigneeObject });
    }, [displayedActivityUsers, setActivityUser]);

    useEffect(() => {
        if (users) {
            setActivityUser(users);
        }
    }, [users, setActivityUser]);

    useEffect(() => {}, [displayedActivityUsers]);

    useEffect(() => {}, [displayedAttachments]);

    useEffect(() => {
        referencedHierarchy.current = displayedHierarchy;
    }, [displayedHierarchy]);

    useEffect(() => {
        if (activityStatus) {
            setActivities({});
            window.location.reload();
        }
    }, [activityStatus, setActivities]);

    useEffect(() => {
        setTimeout(() => {
            if (isCreatingPDF) {
                exportPDF();
            }
        }, 1000);
    }, [isCreatingPDF, exportPDF]);

    const allCompleteTest = group => group[1].status === 'complete';
    const notCompleteTest = group => group[1].status !== 'complete';

    const allComplete = Object.entries(displayedActivityUsers).every(
        allCompleteTest
    );
    const allButOneComplete =
        Object.entries(displayedActivityUsers).filter(allCompleteTest)
            .length ===
        Object.entries(displayedActivityUsers).length - 1;
    const notCompleteResults = Object.entries(displayedActivityUsers).filter(
        notCompleteTest
    );
    const notCompleteSubResults =
        notCompleteResults.length > 0 ? notCompleteResults[0] : [];
    const notCompleteUsers =
        notCompleteSubResults.length > 0 ? notCompleteSubResults[1] : [];
    const inAllButOne =
        allButOneComplete && notCompleteUsers?.users.length > 0
            ? notCompleteUsers?.users.filter(function (user) {
                  return user.id === parseInt(userId);
              }).length > 0
            : false;

    const buttonType = closed
        ? 'reopen'
        : !hasPermissionInOptionsToClose && !closed
        ? 'directClose'
        : allComplete && !closed
        ? 'close'
        : allButOneComplete && inAllButOne && !closed
        ? 'closeAndComplete'
        : userStatus === 'complete' || closed
        ? 'none'
        : userGroup && userGroup.includes('review')
        ? 'review'
        : userGroup && userGroup.includes('assign')
        ? 'complete'
        : 'none';

    const ButtonComponent = activityMap.current[buttonType];

    const isDisabled = false;

    async function onDownloadItem(itemData) {
        const item = itemData[getSchemaDataName(schema)].at(0);
        if (!item) return;

        const dataItem = activitesData?.[getSchemaDataName(schema)].find(
            ({ id }) => id === item.id
        );
        if (!dataItem) return;

        const reportData = {};
        reportData[getSchemaDataName(schema)] = [dataItem];

        exportDetailedActivities({
            data: reportData,
            isAll: true,
            name: 'Activity',
            querySchemas,
            schema,
            tenants,
            userId
        });
    }

    return (
        <PageBody isLoading={loading || !activityRecord}>
            {showExportModal && (
                <ExportModal
                    hasPDFButton={true}
                    isCreatingPDF={isCreatingPDF}
                    isOpen={showExportModal}
                    onClose={onHideExportModal}
                    onExportExcel={() => onDownloadItem(data)}
                    onExportPDF={() => setIsCreatingPDF(true)}
                    title="Export Activity"
                />
            )}

            {deleted && <DangerNotice>{DELETED}</DangerNotice>}

            <WithPageTitle
                className={styles.withPageTitle}
                title={activityName}
            >
                <ButtonComponent
                    activityId={id}
                    activityRecord={activityRecord}
                    isDisabled={isDisabled}
                    riskLabel={riskLabel}
                />

                {hasPermissionToShowPlusButton && (
                    <AddFromActivityButton
                        activityId={activityId}
                        activityRecord={activityRecord}
                        activitySchema={activitySchema}
                        isDisabled={isDisabled}
                        riskLabel={riskLabel}
                    />
                )}

                <SubmitButton onClick={onShowExportModal}>
                    <DownloadIcon fill="white" />
                </SubmitButton>
            </WithPageTitle>

            <ActivityTabs
                activityEntityData={activityEntityData}
                activityLinkages={activityLinkages}
                activityName={activityName}
                activityRecurrence={activityRecurrence}
                activitySchema={activitySchema}
                activityUsersForRecurrence={activityUsersForRecurrence}
                addAssignee={addAssignee}
                addReviewer={addReviewer}
                allAnswers={allAnswers}
                allEntityData={allEntityData}
                allForms={allForms}
                allPreviouslyAssignedUsers={allPreviouslyAssignedUsers}
                answers={answers}
                attachments={displayedAttachments}
                attachmentsOnDelete={attachmentsOnDelete}
                attachmentsOnUpdate={attachmentsOnUpdate}
                caseLinkages={caseLinkages}
                closed={closed}
                comments={comments}
                dropdowns={dropdowns}
                dueDate={dueDate}
                entities={entities}
                forms={forms}
                formsRef={formsRef}
                groups={groups}
                handleFileUpload={handleFileUpload}
                hierarchical={hierarchical}
                history={history}
                id={id}
                instructions={instructions}
                isCreatingPDF={isCreatingPDF}
                logLinkages={logLinkages}
                otherAnswers={otherAnswers}
                otherForms={otherForms}
                policyReference={policyReference}
                questions={questions}
                recurrenceType={recurrenceType}
                recurrences={recurrences}
                referencedUsers={referencedUsers}
                refetchActivity={refetchActivityData}
                regulatoryReferences={regulatoryReferences}
                riskLabel={riskLabel}
                sendEmail={sendEmail}
                startDate={startDate}
                type={type}
                userGroup={userGroup}
                userStatus={userStatus}
                users={displayedActivityUsers}
            />
        </PageBody>
    );
};

export default Activity;
