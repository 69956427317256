import { useLocalStorage } from './use-local-storage.hook';

const { addActivityQuestionsStorgeId } = require('constants');

export function useFormCache({ activityId, formId } = {}) {
    const storage = useLocalStorage();

    function getStorageKey({ activityId, formId } = {}) {
        return [addActivityQuestionsStorgeId, activityId, formId]
            .filter(Boolean)
            .join(':');
    }

    function saveQuestionsToStorage(answers = {}) {
        localStorage.setItem(
            getStorageKey({ activityId, formId }),
            JSON.stringify(answers)
        );
    }

    function clearStorage() {
        localStorage.removeItem(addActivityQuestionsStorgeId); // Old storage key
        clearStorageByKey({ activityId, formId });
    }

    function clearStorageByKey({ activityId, formId } = {}) {
        const keys = storage.getStorageKeysStartsWith(
            getStorageKey({ activityId, formId })
        );
        keys.forEach(key => localStorage.removeItem(key));
    }

    function loadQuestionsFromStorage() {
        const questions = localStorage.getItem(
            getStorageKey({ activityId, formId })
        );
        return questions ? JSON.parse(questions) : undefined;
    }

    return {
        clearStorage,
        clearStorageByKey,
        loadQuestionsFromStorage,
        saveQuestionsToStorage
    };
}
