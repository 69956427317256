import { useCallback, useEffect, useState } from 'react';

import useVariableForm from 'hooks/useVariableForm';
import SectionWithBorder from 'components/SectionWithBorder';
import Title from 'components/Title';
import SaveButton from 'components/SaveButton';

import useTranslation from './hooks/useTranslation';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';

import useData from './hooks/useData';
import useStatus from 'pages/Activity/hooks/useStatus';
import ActivityFormComponent from 'components/ActivityFormComponent';

const AddCaseQuestions = props => {
    const { caseId, className = '', formId, inactive=false, questions:presetQuestions } = props;
    
    const {
        // NEXT,
        QUESTIONS,
        // VIEW_FULL_FORM
    } = useTranslation();

    const [questions, setQuestions] = useState(presetQuestions);
    const [updatedQuestions, setUpdatedQuestions] = useState([]);
    const [_errorMessage, setErrorMessage] = useState();
    const [caseStatus, /*setActivityStatus*/] = useStatus();
    const [createdFormSubmission, createFormSubmission, reset] = useData();
    const [closeAfterSubmit, /*setCloseAfterSubmit*/] = useState(false);
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const form = useVariableForm();

    const { handleForm } = form;
    const { handleError, handleSubmit, handleValid, handleValidPre } = form;

    // const navigate = useNavigate();

    const handleSave = useCallback(() => {
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }
        const formObject = {};
        const newAnswers = [];
        for(const newAnswer of updatedQuestions){
            for(const ques of questions){
                if(ques?.id === newAnswer){
                    if(ques?.answer){
                        newAnswers.push({answer: ques?.answer, questionId:ques?.id});
                    }
                }
            }
        }

        formObject['answers'] = newAnswers;
        formObject['caseId'] = caseId;
        formObject['formId'] = formId;
        formObject['userId'] = parseInt(userId);
        createFormSubmission(formObject);
        return formObject;
        //eslint-disable-next-line
    }, [handleForm, setErrorMessage, createFormSubmission, caseId, formId, userId, questions]);


    // const handleClose = useCallback(() => {
    //     setCloseAfterSubmit(true);
    //     const formObject = handleSave();
    //     setActivityStatus({
    //         id: caseId,
    //         status: 'complete'
    //     });
    // }, [caseId, handleSave, setActivityStatus, setCloseAfterSubmit]);


    useEffect(() => {
        if (!createdFormSubmission) {
            return;
        }

        if(createdFormSubmission) {
            if(!closeAfterSubmit){
                window.location.reload();
            }
        }

        if (caseStatus && caseStatus?.status === 'complete'){
            window.location.reload();
        }

        // navigate(ACTIVITY_ROUTE.replace(':caseId', createdFormSubmission));

        reset();
    }, [/*navigate,*/ reset, createdFormSubmission, closeAfterSubmit, caseStatus]);

    useEffect(() => {
        const filteredQuestions = questions.filter(question => question?.type === 'checkBoxInput');
        for(const question of filteredQuestions){
            const answerArray = question?.answer ? question?.answer.split(',').map(x => parseInt(x)): [];
            for(const answer of answerArray){
                handleValidPre(`${question?.id}_${answer}`, 'true')
            }
        }
    //eslint-disable-next-line
    }, [questions])

    return (
        <SectionWithBorder
            className={`${className} ${styles.addActivityQuestions}`}
        >
            <Title className={styles.title} text={QUESTIONS} />
        
            {questions.map(({answer, choices, formComponent, id, index, questionId, name, type}) => {
                if(formComponent === "newForm"){
                    return(
                        <ActivityFormComponent
                            answer={answer}
                            choices={choices}
                            formComponent={formComponent}
                            handleError={handleError}
                            handleForm={handleForm}
                            handleSubmit={handleSubmit}
                            handleValid={handleValid}
                            id={id}
                            inactive={inactive}
                            index={index}
                            name={name}
                            questionId={questionId}
                            questions={questions}
                            setQuestions={setQuestions}
                            setUpdatedQuestions={setUpdatedQuestions}
                            type={type}
                            updatedQuestions={updatedQuestions}
                        />
                    );
                }else{
                    return <></>;
                }
            })}

            {/* <FullFormDrawer
                handleError={handleError}
                handleSave={handleSave}
                handleSaveClose={handleClose}
                handleSubmit={handleSubmit}
                handleValid={handleValid}
                questions={questions}
            >
                {({ toggle }) => (
                    <InfoHollowButton
                        className={styles.infoHollowButton}
                        onClick={toggle}
                        text={VIEW_FULL_FORM}
                    />
                )}
            </FullFormDrawer> */}

            {!inactive &&
                <SaveButton
                    className={styles.saveButton} 
                    onClick={handleSave}
                    saveText={"Save"}
                />
            }

            {/* <SaveButton
                className={styles.saveAndCloseButton} 
                onClick={handleClose}
                saveText={"Save and Complete"}
            /> */}
        </SectionWithBorder>
    );
};

export default AddCaseQuestions;
